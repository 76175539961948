import React, { useState } from 'react';
import styles from './PassportBlock.module.css';
//* Графика
import PassportGirl from '../../assets/PassportGirl.png';
import KioskMask from '../../assets/kioskMask.png';
import PassportMask from '../../assets/passportMask.png';
import Profile from '../../assets/profile.png';
import Qr from '../../assets/qrPassport.png';
//* Модалки
import ModalKiosk from '../../ui/modal/ModalKiosk';

const PassportBlock = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  //* Хэндлеры для открытие и закрытие модалок
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div
      className={styles.root}
      id="passport">
      <ModalKiosk
        isOpen={isModalOpen}
        onClose={closeModal}
      />
      <div className={styles.passport_wrapper}>
        <div className={styles.pic_wrapper}>
          <img
            className={styles.passportGirl}
            src={PassportGirl}
            alt="PassportGirl"
          />
        </div>
        <div className={styles.block_wrapper}>
          <div className={styles.passport_title}>
            Паспорт обученности / Инфокиоск
          </div>
          <div className={styles.first_wrapper}>
            <img
              className={styles.passportMask}
              src={PassportMask}
              alt="PassportMask"
            />
            <div className={styles.title}>
              Единый электронный паспорт обученности
            </div>
            <div className={styles.description}>
              Представляет собой набор данных, позволяющих увидеть всю историю
              обучения и проверки знаний человека.
            </div>
            <div className={styles.card}>
              <div className={styles.id}>ID: 5021</div>
              <div className={styles.photo}>
                <img
                  src={Profile}
                  alt="Profile"
                />
              </div>
              <div className={styles.place}>
                АНО ДПО "ЮГОРСКИЙ УЧЕБНЫЙ ЦЕНТР"
              </div>
              <a
                href="https://passport.lms.simax-it.ru/?qrcode=f27e8b27213bee66c44a6032f1fe6bc2"
                target="blank">
                <div className={styles.qr}>
                  <img
                    src={Qr}
                    alt="Qr"
                  />
                </div>
              </a>
              <div className={styles.titlePass}>Паспорт обучения</div>
              <div className={styles.name}>
                Дуюнов <br />
                Эдуард
                <br /> Николаевич
              </div>
            </div>
          </div>

          <div className={styles.second_wrapper}>
            <img
              className={styles.kioskMask}
              src={KioskMask}
              alt=""
            />
            <div className={styles.title}>
              Программно-аппаратный комплекс для осуществления обучения и
              аттестации
            </div>
            <div className={styles.description}>
              Разработанный компанией «Симакс» мобильный инфокиоск быть
              использован в качестве демонстрационной платформы и интерактивного
              информационного ресурса, позволяя обучающимся воспринимать
              информацию в удобном, инновационном формате. Инфокиоск может быть
              наполнен индивидуальной информацией под конкретного заказчика, а
              каждый пользователь имеет доступ к его личному прогрессу по
              qr-коду.
            </div>

            <div
              className={styles.btnMore}
              onClick={openModal}>
              Как это работает?
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PassportBlock;
