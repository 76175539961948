import { createStore } from 'effector';
import KarevaV from '../assets/team/ВалерияК.png';
import BitykovD from '../assets/team/ДмитрийБ.png';
import PelihovkiyD from '../assets/team/ДмитрийП.png';
import GolenY from '../assets/team/ЮлияГ.png';
import PurilovaI from '../assets/team/ИринаП.png';
import VeligockihR from '../assets/team/РоманВ.png';
import ShadrinE from '../assets/team/ЕвгенийШ.png';
import BlagodatskihV from '../assets/team/ВиталийБ.png';
import IsupovA from '../assets/team/АндрейИ.png';
import ShkrebenokV from '../assets/team/ВасилийШ.png';
import GakA from '../assets/team/АнастасияГ.png';
import FateevaS from '../assets/team/СветланаФ.png';
import IvaschenkoA from '../assets/team/АннаИ.png';
import BitykovaE from '../assets/team/ЕкатеринаБ.png';
import MaidanovaE from '../assets/team/ЕленаМ.png';

import GaskovaK from '../assets/team/КсенияГ.png';
import BobritskayaN from '../assets/team/НатальяБ.png';

const ourTeamSrc = [
  {
    id: 1,
    src: KarevaV,
    name: 'Карева Валерия',
    post: 'Генеральный директор',
  },
  {
    id: 2,
    src: BitykovD,
    name: 'Битюков Дмитрий',
    post: 'Коммерческий директор',
  },
  {
    id: 3,
    src: PelihovkiyD,
    name: 'Пелиховский Дмитрий',
    post: 'Руководитель отдела разработки',
  },
  {
    id: 4,
    src: GolenY,
    name: 'Голень Юлия',
    post: 'Директор по продажам',
  },
  {
    id: 5,
    src: PurilovaI,
    name: 'Путилова Ирина',
    post: 'Руководитель отдела обучения',
  },
  {
    id: 6,
    src: VeligockihR,
    name: 'Велигоцкий Роман',
    post: 'Техлид',
  },
  {
    id: 7,
    src: ShadrinE,
    name: 'Шадрин Евгений',
    post: 'Инженер по тестированию',
  },
  {
    id: 8,
    src: BlagodatskihV,
    name: 'Благодатских Виталий',
    post: 'SEO специалист',
  },
  {
    id: 9,
    src: IsupovA,
    name: 'Исупов Андрей',
    post: 'Фронтенд-разработчик',
  },
  {
    id: 10,
    src: ShkrebenokV,
    name: 'Шкребенок Василий',
    post: 'Фронтенд-разработчик',
  },
  {
    id: 11,
    src: GakA,
    name: 'Гак Анастасия',
    post: 'Методист',
  },
  {
    id: 12,
    src: FateevaS,
    name: 'Фатеева Светлана',
    post: 'Cпециалист по обучению',
  },
  {
    id: 13,
    src: IvaschenkoA,
    name: 'Анна Иващенко',
    post: 'Специалист отдела обучения, методист',
  },
  {
    id: 14,
    src: MaidanovaE,
    name: 'Елена Майданова',
    post: 'Специалист отдела обучения',
  },
  {
    id: 15,
    src: BitykovaE,
    name: 'Екатерина Битюкова',
    post: 'Специалист отдела обучения',
  },
  {
    id: 16,
    src: GaskovaK,
    name: 'Ксения Гаськова',
    post: 'Специалист отдела обучения',
  },
  {
    id: 17,
    src: BobritskayaN,
    name: 'Наталья Бобрицкая',
    post: 'Специалист отдела обучения',
  },
];

export const ourTeamSrcData = createStore(ourTeamSrc);
