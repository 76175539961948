import React, { useEffect, useState } from 'react';
import styles from './HeaderBanner.module.css';
//* Графика
import Banner from '../../assets/desctop.png';
import BannerMobile from '../../assets/mobile.png';
import Simax from '../../assets/Logos/simax.png';
import Dpo from '../../assets/Logos/dpo.png';
import Yuc from '../../assets/Logos/yuc.png';

const HeaderBanner = () => {
  const [isMobile, setIsMobile] = useState(Banner);

  //* Юзэффект для изменения главно баннера (Десктоп/Мобильные устройства)
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 700) {
        setIsMobile(BannerMobile);
      } else {
        setIsMobile(Banner);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className={styles.root}
      id="home">
      <div className={styles.banner_wrapper}>
        <div className={styles.logos}>
          <a
            className={styles.link}
            href="https://simax-it.ru/"
            target="_blank"
            rel="noreferrer">
            <img
              src={Simax}
              alt=""
            />
          </a>
          <div className={styles.line}></div>
          <a
            className={styles.link}
            href="https://centr-ugra.ru/"
            target="_blank"
            rel="noreferrer">
            <img
              src={Yuc}
              alt=""
            />
          </a>
          <div className={styles.line}></div>
          <a
            className={styles.link}
            href="https://dpo-stbt.ru/"
            target="_blank"
            rel="noreferrer">
            <img
              src={Dpo}
              alt=""
            />
          </a>
        </div>
        <div className={styles.logosMobile}>
          <a
            className={styles.link}
            href="https://simax-it.ru/"
            target="_blank"
            rel="noreferrer">
            <img
              src={Simax}
              className={styles.simax}
              alt=""
            />
          </a>
          <a
            className={styles.link}
            href="https://dpo-stbt.ru/"
            target="_blank"
            rel="noreferrer">
            <img
              src={Dpo}
              className={styles.dpo}
              alt=""
            />
          </a>
          <a
            className={styles.link}
            href="https://centr-ugra.ru/"
            target="_blank"
            rel="noreferrer">
            <img
              src={Yuc}
              className={styles.yuc}
              alt=""
            />
          </a>
        </div>
        <img
          src={isMobile}
          alt="banner_wrapper"
        />
      </div>
    </div>
  );
};

export default HeaderBanner;
