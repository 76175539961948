import React from 'react';
import styles from './StatisticMap.module.css';
import Map from '../../assets/SlidesStatistic/mapmap.png';
import MaskMap from '../../assets/SlidesStatistic/maskMap.png';

const StatisticMap = () => {
  return (
    <div className={styles.root}>
      <div className={styles.map_wrapper}>
        <img
          className={styles.map}
          src={Map}
          alt=""
        />
        <img
          className={styles.mask_map}
          src={MaskMap}
          alt=""
        />
        <div className={styles.line_fill}></div>
      </div>
    </div>
  );
};

export default StatisticMap;
